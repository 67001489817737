// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-1-index-mdx": () => import("./../../../../src/1-index.mdx" /* webpackChunkName: "component---src-1-index-mdx" */),
  "component---src-2-use-cases-mdx": () => import("./../../../../src/2-use-cases.mdx" /* webpackChunkName: "component---src-2-use-cases-mdx" */),
  "component---src-3-quick-start-mdx": () => import("./../../../../src/3 - quick start.mdx" /* webpackChunkName: "component---src-3-quick-start-mdx" */),
  "component---src-4-1-token-types-mdx": () => import("./../../../../src/4-1 - token types.mdx" /* webpackChunkName: "component---src-4-1-token-types-mdx" */),
  "component---src-4-2-pdf-tokens-mdx": () => import("./../../../../src/4-2 - pdf tokens.mdx" /* webpackChunkName: "component---src-4-2-pdf-tokens-mdx" */),
  "component---src-4-3-ms-word-tokens-mdx": () => import("./../../../../src/4-3 - ms word tokens.mdx" /* webpackChunkName: "component---src-4-3-ms-word-tokens-mdx" */),
  "component---src-4-4-pixel-image-mdx": () => import("./../../../../src/4-4 - pixel-image.mdx" /* webpackChunkName: "component---src-4-4-pixel-image-mdx" */),
  "component---src-4-5-email-mdx": () => import("./../../../../src/4-5 - email.mdx" /* webpackChunkName: "component---src-4-5-email-mdx" */),
  "component---src-4-5-links-mdx": () => import("./../../../../src/4-5 - links.mdx" /* webpackChunkName: "component---src-4-5-links-mdx" */),
  "component---src-4-6-windows-folder-mdx": () => import("./../../../../src/4-6 - windows folder.mdx" /* webpackChunkName: "component---src-4-6-windows-folder-mdx" */),
  "component---src-4-7-dns-mdx": () => import("./../../../../src/4-7 - DNS.mdx" /* webpackChunkName: "component---src-4-7-dns-mdx" */),
  "component---src-5-notification-groups-mdx": () => import("./../../../../src/5 - notification groups.mdx" /* webpackChunkName: "component---src-5-notification-groups-mdx" */),
  "component---src-6-ip-suppression-groups-mdx": () => import("./../../../../src/6 - IP suppression groups.mdx" /* webpackChunkName: "component---src-6-ip-suppression-groups-mdx" */),
  "component---src-7-billing-mdx": () => import("./../../../../src/7 - billing.mdx" /* webpackChunkName: "component---src-7-billing-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

